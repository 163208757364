<template>
  <b-modal
      :id="removeModalName"
      :ref="removeModalName"
      footer-class="flex-nowrap"
      no-close-on-backdrop
      centered
  >
    <template #modal-header>
      <h3>{{ $t('modals.deleteItemFromForm.title') }}</h3>
    </template>
    <div class="delete-icon text-center px-2">
      <b-icon icon="exclamation-triangle-fill" variant="danger" class="mt-5 mb-4"></b-icon>
      <div class="mt-2 mb-4" v-html="$t('modals.deleteItemFromForm.message')"></div>
    </div>
    <template #modal-footer="{ close }">
      <b-button
          class="p-3 border-right-light-grey"
          block
          @click="close()"
      >
        {{ $t('buttons.cancel') }}
      </b-button>
      <b-button
          variant="primary"
          class="p-3"
          block
          @click="removeItem"
      >
        {{ $t('modals.deleteItemFromForm.validate') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalRemoveItemFromForm',
  props: {
    item: {
      type: Object,
      required: true,
    },
    formIndex: {
      type: Number,
      default: null,
    },
  },
  computed: {
    removeModalName() {
      let formIndexString = '';
      if (this.formIndex) {
        formIndexString = `-${this.formIndex}`;
      }
      return `remove-${this.item.type}-${this.item.id}${formIndexString}`;
    },
  },
  methods: {
    async removeItem() {
      this.$emit('remove-item', this.item);
      this.$refs[this.removeModalName].hide();
    },
  },
};
</script>

<style scoped lang="scss">
.delete-icon svg {
  font-size: 80px;
}
</style>
